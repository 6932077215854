$site: 'go' !default;
$sitefull: 'geekingout' !default;
$sitedomain: 'geekingout.ca' !default;

// Brand
$color-brand-primary: #3B1440 !default;
$color-brand-primary-text: #ffffff !default;
$color-brand-secondary: #B4D337 !default;
$color-brand-secondary-text: #000000 !default;
$color-brand-tertiary: #4EBFA8 !default;
$color-brand-tertiary-text: #000000 !default;

// Font definitions
$font-map: (
        'open-sans-regular': (
                font-family: 'Open Sans',
                font-weight: 400
        ),
        'open-sans-bold': (
                font-family: 'Open Sans',
                font-weight: 700
        ),
        'ubuntu-regular': (
                font-family: 'Ubuntu',
                font-weight: 400
        ),
        'ubuntu-sans-bold': (
                font-family: 'Ubuntu',
                font-weight: 500
        ),
        'ubuntu-sans-xbold': (
                font-family: 'Ubuntu',
                font-weight: 700
        )
) !default;

// Font definitions
$font-paragraph: "open-sans-regular", Helvetica, Arial, sans-serif !default;
$font-paragraph-extra-light: "open-sans-regular", Helvetica, Arial, sans-serif !default;
$font-paragraph-light: "open-sans-regular", Helvetica, Arial, sans-serif !default;
$font-paragraph-book: "open-sans-regular", Helvetica, Arial, sans-serif !default;
$font-paragraph-medium: "open-sans-regular", Helvetica, Arial, sans-serif !default;
$font-paragraph-semibold: "open-sans-bold", "unineue-bold-fallback", Helvetica, Arial, sans-serif !default;
$font-paragraph-bold: "open-sans-bold", "unineue-bold-fallback", Helvetica, Arial, sans-serif !default;
$font-heading-light: "ubuntu-sans-regular", Helvetica, Arial, sans-serif !default;
$font-heading-regular: "ubuntu-sans-regular", Helvetica, Arial, sans-serif !default;
$font-heading-semibold: "ubuntu-sans-bold", Helvetica, Arial, sans-serif !default;
$font-heading-bold: "ubuntu-sans-bold", Helvetica, Arial, sans-serif !default;
$font-heading-xbold: "ubuntu-sans-xbold", Helvetica, Arial, sans-serif !default;
$font-default: $font-paragraph-medium !default;