$body-header-background-color: var(--body-background-color) !default;
$header-top-background-color--scrolled: var(--body-background-color) !default;

$header-logo-aspect-ratio: 3.29 !default;
$header-logo-vertical-margin: 8px !default;
$header-logo-vertical-margin--tablet: 10px !default;

$header-top-height: 60px !default;
$header-top-height--tablet: 75px !default;
$header-top-height--scrolled: 60px !default;
$header-top-height--tablet-scrolled: 75px !default;

$header-top-drawer-toggle-background-color: transparent !default;
$header-top-drawer-toggle-text-color: inherit !default;
$header-top-drawer-toggle-width: 60px !default;
$header-top-alert-bar-background-color: $color-brand-primary !default;
$header-top-alert-bar-text-color: $color-brand-primary-text !default;

$header-top-brand-width--title: 26px !default;


// Cover definitions
$block-cover-brand-height: 76px !default; 
$block-cover-brand-width--title: 87px !default;

$block-header-text-color: var(--body-heading-text-color) !default;
$bullet-tag-color: $color-brand-primary !default;
$bullet-tag-text-color--cover-tablet: $color-brand-secondary !default;
$bullet-tag-font-family: $font-paragraph-medium !default;
$article-body-blockquote-highlight-color: #D8EBEA !default;
$article-body-blockquote-text-decoration-line: underline !default;
$article-body-blockquote-text-decoration-thickness: 7px !default;
$article-body-blockquote-text-decoration-thickness--embellish: 5px !default;
$quote-pull-line-height: 1.5 !default;


$cta-background-color: $color-brand-secondary !default;
$cta-background-color--hover: $color-brand-primary !default;
$cta-border-radius: 0 !default;
$cta-text-color: $color-brand-secondary-text !default;
$cta-text-color-hover: $color-brand-primary-text !default;
$cta-font-family: $font-paragraph-bold !default;

$article-body-reco-block-border-radius: 0 !default;
$article-body-reco-block-border-radius--slim: 0 !default;
$article-body-reco-block-category-border-radius: 0 default;
$article-body-reco-block-image-border-radius: 0 !default;
$article-body-reco-block-image-border-radius--compact: 0 !default;
$reco-block-category-font-family: $font-paragraph-bold !default;
$reco-block-button-font-family: $font-paragraph-bold !default;
$article-body-reco-block-collection-shop-link-border-radius: 0 !default;


$article-update-bg-color: 'hsl(107deg 33% 68% / 10%)' !default;
$article-update-border-color: 'hsl(107deg 33% 68% / 30%)' !default;
$author-signature-text-color: $color-brand-primary !default;
$best-picks-award-text-color: #000 !default;
$best-picks-award-site-name-text-color: $color-brand-primary !default;
$best-picks-award-site-name-text-color: $color-brand-primary !default;
$breadcrumb-text-transform: uppercase !default;
$breadcrumb-font-weight: 700 !default;
$icon-button-filter: none !default;
$icon-button-filter--hover: invert(1) !default;
$panel-heading-bar-color--info: $color-brand-secondary !default;
$panel-heading-text-color--info: $color-brand-secondary-text !default;
$panel-info-background-color: 'hsl(38deg 97% 60% / 10%)' !default;
$panel-info-border-color: 'hsl(38deg 97% 60% / 20%)' !default;
$newsletter-btn-radius: 0 !default;
$quick-shop-button-border-color: $color-brand-secondary !default;
$rating-score-font: $font-paragraph-bold !default;
$rating-score-out-of-font: $font-paragraph-medium !default;
$rating-star-custom-color: $color-brand-secondary !default;
$shop-bar-item-category-font-family: $font-paragraph-bold !default;
$shop-bar-item-link-border-radius: 0px !default;
$shop-bar-logo-aspect-ratio: 0.66 !default;
$testimonial-link-color--even: $color-brand-primary !default;
$versus-product-font-family: $font-paragraph-bold !default;
$versus-dot-border-radius: 0% !default;
